import React, { useRef } from 'react'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import consult from "../images/consult.svg";
import plan2 from "../images/plan.svg";
import demolish from "../images/demolish.svg";
import build from "../images/build.svg";
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import "../static/home.less";


const Content1 = (props)  => {
 
        return (
            <div className="home-page page1" >
                <div className="home-page-wrapper" id="page1-wrapper">
                    <OverPack>
                    <QueueAnim
                                        key="queSpecializeRowHeader"
                                        type="bottom"
                                        leaveReverse
                                        ease="easeInOutBack"
                                        delay={[200, 900]}>
                                             <Row key="specializeRow" className='specializeRowHeader'>
                        <Col span={24} key="specializeCol">
                            <h2>We specialize in:</h2>
                            </Col>
                            </Row>
                     </QueueAnim>
                    <QueueAnim
                                        key="queSpecialize"
                                        type="bottom"
                                        leaveReverse
                                        ease="easeInOutBack"
                                        delay={[300, 800]}>
                    <Row key="specializeRow" className='specializeRow'>
                            <Col span={24}>
                            <Row justify="center" align="middle">
                    <Col xs={{ span: 24 }} lg={{ span: 4}} key="specializeCol">
                    <QueueAnim
                                    key="specializeRowQue1"
                                    type="bottom"
                                                duration={[1000, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[200, 200]} delay={[100, 1300]}>
                        <div className="specializeCol" key="specializeCol1"><h3>Custom home framing</h3></div>
                        </QueueAnim>
                        </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 4}} key="specializeCol">
                    <QueueAnim
                                    key="specializeRowQue1"
                                    type="bottom"
                                                duration={[1000, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[200, 200]} delay={[200, 1400]}>
                        <div className="specializeCol" key="specializeCol2"><h3>Addition framing</h3></div>
                        </QueueAnim>
                        </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 4}} key="specializeCol">
                    <QueueAnim
                                    key="specializeRowQue1"
                                    type="bottom"
                                                duration={[1000, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[200, 200]} delay={[300, 1500]}>
                        <div className="specializeCol" key="specializeCol3"><h3>Bearing wall removal</h3></div>
                        </QueueAnim>
                        </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 4}} key="specializeCol">
                    <QueueAnim
                                    key="specializeRowQue1"
                                    type="bottom"
                                                duration={[1000, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[200, 200]} delay={[400, 1600]}>
                        <div className="specializeCol" key="specializeCol4"><h3>Steel beam installation</h3></div>
                        </QueueAnim>
                        </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 4 }} key="specializeCol">
                    <QueueAnim
                                    key="specializeRowQue1"
                                    type="bottom"
                                                duration={[1000, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[200, 200]} delay={[500, 1700]}>
                        <div className="specializeCol" key="specializeCol5"><h3>Deck building</h3></div>
                        </QueueAnim>
                        </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 4}} key="specializeCol">
                    <QueueAnim
                                    key="specializeRowQue1"
                                    type="bottom"
                                                duration={[1000, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[200, 200]} delay={[600, 1800]}>
                        <div className="specializeCol" key="specializeCol6"><h3>Basement framing</h3></div>
                        </QueueAnim>
                        </Col>
                    </Row>
                            </Col>
                    </Row>
                    </QueueAnim>
                    <QueueAnim
                                        key="queWhatCanWeDoh2"
                                        type="bottom"
                                        leaveReverse
                                        ease="easeInOutBack"
                                        delay={[1800, 600]}>
                    <h2 key="whatCanweDo">What can <span>We</span> do for you </h2>
                    </QueueAnim>
                    <QueueAnim
                                    key="queWhatCanWeDoRow"
                                    type="bottom"
                                             leaveReverse
                                                ease="easeInOutBack"
                                                delay={[1900, 500]}>
                        <Row key="whatcanwedo-row" className="whatCanWeDo">
                            <Col xs={{ span: 12, offset: 6 }} lg={{ span: 4, offset: 2 }} key="col-1">
                                <QueueAnim
                                    key="que1"
                                    type="bottom"
                                                duration={[1000, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[100, 200]} delay={[100, 1300]}>
                                                    <div key="Consult">
                                        <img src={consult} key="img-1" />
                                        <h2 key="h2-1">Consult</h2>
                                        <p key="p-1">Any framing questions you have, we are happy to answer. Any ideas you have, we are happy to provide feedback and suggestions.</p>
                                        </div>
                                </QueueAnim>
                                </Col>
                                <Col xs={{ span: 12, offset: 6 }} lg={{ span: 4, offset: 2 }} key="col-2">
                                    <QueueAnim
                                        key="que2"
                                        type="bottom"
                                                duration={[1000, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[100, 200]} delay={[200, 1200]}>
                                                    <div key="Plan">
                                            <img src={plan2} key="img-2" />
                                            <h2 key="h2-2">Plan</h2>
                                            <p key="p-2">Planning framing project could be frustrating. If any help is needed in material planning, research and supply, we are here to help!</p>  
                                    </div>
                                    </QueueAnim>
                                </Col>
                                <Col xs={{ span: 12, offset: 6 }} lg={{ span: 4, offset: 2 }} key="col-3">
                                    <QueueAnim
                                        key="que3"
                                        type="bottom"
                                                duration={[1000, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[100, 300]} delay={[300, 1100]}>
                                                    <div key="Demolition">
                                            <img src={demolish} key="img-3" />
                                            <h2 key="h2-3">Demolish</h2>
                                            <p key="p-3">Roof or bearing wall removal - isnt a problem for us. We guarantee safe and clean demolition.</p>
                                            </div>
                                    </QueueAnim>
                                </Col>
                                <Col xs={{ span: 12, offset: 6 }} lg={{ span: 4, offset: 2 }} key="col-4">
                                    <QueueAnim
                                        key="que4"
                                        type="bottom"
                                                duration={[1000, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[100, 400]} delay={[400, 1000]}>
                                                    <div key="Build">
                                            <img src={build} key="img-4" />
                                            <h2 key="h2-4">Build</h2>
                                            <p key="p-4">Once everything has been gone through with the client, the construction will begin.</p>
                                    </div>
                                    </QueueAnim>
                                </Col>
                        </Row>
                        </QueueAnim>
                        </OverPack>
                </div>
            </div>
        );
    }

Content1.propTypes = {
    isMobile: PropTypes.bool.isRequired,
};

export default Content1;
