import React, { useRef } from 'react'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import i1 from "../images/i1.png";
import i2 from "../images/i2.png";
import i3 from "../images/i3.png";
import i4 from "../images/i4.png";
import i5 from "../images/i5.png";
import i6 from "../images/i6.png";
import i7 from "../images/i7.png";
import i8 from "../images/i8.png";
import i9 from "../images/i9.jpg";
import i10 from "../images/i10.jpg";
import i11 from "../images/i11.jpg";

import { Row, Col, Carousel } from 'antd';
import PropTypes from 'prop-types';
import "../static/home.less";
import emailjs from '@emailjs/browser';

const Content2 = (props)  => {
    const formRef = useRef();

    const onFinish = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_397ytr9', 'template_7tuvd0e', formRef.current, 'OqH5bM1cLte_t-LrH')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
    }

    
        return (
            <div className="contactUs">
                 <OverPack>
                <div className="blueContent"></div>
 <div className="greenContent">
   <div className="blueDiagonal"></div>
   <QueueAnim
                                        key="queWhatCanWeDoh2"
                                        type="bottom"
                                        leaveReverse
                                        ease="easeInOutBack"
                                        delay={[1200, 2000]}>
                                            <div key="galleryHeader" className="galleryHeader">
                                            <h1>Projects gallery</h1>
                                            </div>
                    </QueueAnim>
 <QueueAnim
                                        key="imgCarousel"
                                        type="bottom"
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                delay={[1350, 2000]}>
                                                    <div class="carouselImgs" key="carouselImgs">
                                                        
                                                    <Carousel autoplay>
    <div>
    <img src={i1} key="CarouselImg-1" />
    </div>
    <div>
    <img src={i2} key="CarouselImg-2" />
    </div>
    <div>
    <img src={i3} key="CarouselImg-3" />
    </div>
    <div>
    <img src={i4} key="CarouselImg-4" />
    </div>
    <div>
    <img src={i5} key="CarouselImg-5" />
    </div>
    <div>
    <img src={i6} key="CarouselImg-6" />
    </div>
    <div>
    <img src={i7} key="CarouselImg-7" />
    </div>
    <div>
    <img src={i8} key="CarouselImg-8" />
    </div>
    <div>
    <img src={i9} key="CarouselImg-9" />
    </div>
    <div>
    <img src={i10} key="CarouselImg-10" />
    </div>
    <div>
    <img src={i11} key="CarouselImg-11" />
    </div>
  </Carousel>
                                                    </div>
                        
  </QueueAnim>
   <div className="whiteDiagonal"></div>
 </div>

 
 <div className="whiteContent">
 <div className="contactForm">
                    <br/>
                    
                    <TweenOne key="0" animation={{ opacity: 1 }}
          className="code-box-shape"
          style={{ opacity: 0, marginBottom: 10 }}
        />
        <QueueAnim
                                                key="header"
                                                type="bottom"
                                                duration={[1650, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                interval={[100, 200]} delay={[0, 1000]}
                                                >
        <h1 key="contactHeader">Contact us</h1>
        </QueueAnim>
        
                        <QueueAnim
                                                key="queContactUsForm"
                                                type="bottom"
                                                duration={[3150, 2000]}
                                                ease={['easeOutBack', 'easeInOutCirc']}
                                                leaveReverse
                                                >
                                                    <form key="contactForm" ref={formRef} onSubmit={onFinish}>
                                                    <Row key="nameRow">
                                                        <Col span={24}>
                                                        <input required type="text" placeholder=' Name' name="name"/></Col>
                                                    </Row>
                                <Row key="emailRow">
                                                        <Col span={24}>
                                                        <input required type="text" placeholder=' Email' name="email"/></Col>
                                                    </Row>
                               <Row key="phoneRow">
                                                        <Col span={24}>
                                                        <input type="text" placeholder=' Phone' name="phone"/></Col>
                                                    </Row>
                                    <Row key="messageRow">
                                                        <Col span={24}>
                                                        <textarea required type="text" rows={4} placeholder=' Message' name="message"/></Col>
                                                    </Row>
                                <input type="submit" key="submitButton" value="Send"/>
                                </form>
                </QueueAnim>
                    </div>
 </div>
 
 </OverPack>
            </div>
        );
    }

Content2.propTypes = {
    isMobile: PropTypes.bool.isRequired,
};

export default Content2;
