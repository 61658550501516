import React from 'react'
import PropTypes from 'prop-types';
import "../static/home.less";
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import TweenOne from 'rc-tween-one';
import Parallax from 'rc-scroll-anim/lib/ScrollParallax';
import QueueAnim from 'rc-queue-anim';
import email from "../images/email.png";
import phone from "../images/phone.png";
import instagram from "../images/instagram.png";
import { Row, Col, Divider } from 'antd';

const Footer = (props) => {
  return (
    <div className="footer">
                        <Row className="footer-row">
                            <Col xs={{ span: 20, offset: 4 }} lg={{ span: 5, offset: 2 }} key="footercol-1">
                                                    <Row className="footerRow">
                                                    <Col span={4} >
                                                    <img key="emailImg" src={email} />
                                                    </Col>
                                                    <Col xs={{ span: 20}} lg={{ span: 18 }}>
                                                    <a href="mailto:frameartconstruction@gmail.com?subject='project inquiry'" >frameartconstruction@gmail.com</a>
                                                    </Col>
                                                    </Row>
                                </Col>
                                <Col xs={{ span: 12, offset: 4 }} lg={{ span: 5, offset: 2}} key="footercol-2">
                                <Row className="footerRow">
                                                    <Col xs={{span:6}} lg={{span:4}}>
                                                    <img key="phoneImg" src={phone} />
                                                    </Col>
                                                    <Col key="phoneTextWrapper">
                                                    { props.isMobile ?
                                                    <div>
                                                      <a  href="tel:647-622-3175">(647)-622-3175</a><br/>
                                                      <a  href="tel:647-622-3175">(647)-326-0188</a>
                                                      </div>
                                                      :
                                                      <div>
                                                      <span>(647)-622-3175</span><br/>
                                                      <span>(647)-326-0188</span>
                                                      </div>
}
                                                    </Col>
                                                    </Row>
                                </Col>
                                <Col xs={{ span: 12, offset: 4 }} lg={{ span: 5, offset: 2 }} key="footercol-3">
                                                    <Row>
                                                    <Col xs={{span:6}} lg={{span:4}}>
                                                    <img key="instagramImg" src={instagram} />
                                                    </Col>
                                                    <Col>
                                                    <a href='https://www.instagram.com/frameartconstruction/' target="_blank">Instagram</a>
                                                    </Col>
                                                    </Row>
                                </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                          <small>Copyright © 2022, Frameart Construction, Inc.</small>
                           </Col>
                        </Row>
 </div>
  )
}

Footer.propTypes = {
    isMobile: PropTypes.bool.isRequired,
};

export default Footer