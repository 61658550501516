import React from 'react'
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import logo from "../images/7-1.png";
import "../static/home.less";
import {Carousel} from "antd";
import frameartconstruction11 from "../images/frameartconstruction11.jpg"
import frameartconstruction12 from "../images/frameartconstruction12.jpg"
import frameartconstruction13 from "../images/frameartconstruction13.jpg"
import frameartconstruction14 from "../images/frameartconstruction14.jpg"
import frameartconstruction15 from "../images/frameartconstruction15.jpg"
import frameartconstruction16 from "../images/frameartconstruction16.jpg"

const Banner = (props) => {
  return (
    <div className="banner-wrapper">
            {props.isMobile && (
                <TweenOne animation={{ opacity: 1 }} className="banner-image-wrapper">
                    <div className="home-banner-image">
                        <img
                            alt="banner"
                            src={logo}
                            width="100%"
                        />
                    </div>
                </TweenOne>
            )}
            <QueueAnim className="banner-title-wrapper" type={props.isMobile ? 'bottom' : 'right'}>
                <div key="line" className="title-line-wrapper">
                    <div
                        className="title-line"
                        style={{ transform: 'translateX(-198px)' }}
                    />
                </div>
                <h1 key="h1Content">FRAMEART CONSTRUCTION</h1>
                <h2 key="content">
                    Dream it. Believe it. Build it.
                </h2>
            </QueueAnim>
            {!props.isMobile && (
                <TweenOne animation={{ opacity: 1 }} className="banner-image-wrapper">
                    <img
                        alt="banner"
                        src={logo}
                        width="100%"
                    />
                </TweenOne>
            )}
            <Carousel effect="fade" className="bannerCarousel" autoplay dots = {false}>
                    <img style={{maxHeight:"450px", width: "100%"}} src={ frameartconstruction11}/>
                    <img style={{maxHeight:"450px", width: "100%"}} src={ frameartconstruction12}/>
                    <img style={{maxHeight:"450px", width: "100%"}} src={ frameartconstruction13}/>
                    <img style={{maxHeight:"450px", width: "100%"}} src={ frameartconstruction14}/>
                    <img style={{maxHeight:"450px", width: "100%"}} src={ frameartconstruction15}/>
                    <img style={{maxHeight:"450px", width: "100%"}} src={ frameartconstruction16}/>
                </Carousel>
        </div>
  )
}

Banner.propTypes = {
    isMobile: PropTypes.bool.isRequired,
};

export default Banner