import React from 'react';
import DocumentTitle from 'react-document-title';
import { enquireScreen } from 'enquire-js';

import Banner from './Banner';
import Content1 from './Content1';
import Content2 from './Content2';
import Footer from './Footer';
import '../static/style';
import 'antd/dist/antd.min.css'

let isMobile;

enquireScreen((b) => {
  isMobile = b;
});

class Home extends React.PureComponent {
  state = {
    isMobile,
  }
  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }
  render() {
    return (
      <DocumentTitle title="Frameart Construction">
        <div>
          <div className="home-wrapper">
            <Banner isMobile={this.state.isMobile} />
            <Content1 isMobile={this.state.isMobile} />
            <Content2 isMobile={this.state.isMobile} />
            <Footer />
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default Home;
